import React, { useEffect, useRef } from 'react'
import NextImage, { ImageProps } from 'next/image'

export default function Image(props: ImageProps) {
  const rootRef = useRef()

  useEffect(() => {
    if (document) {
      rootRef.current = document.querySelector('#main-scroll')
    }
  })

  return <NextImage lazyRoot={rootRef.current} {...props} />
}