const Config = {
  phone: '01273833722',
  phoneDisplay: '01273 833 722',
  email: 'info@breakeven.org.uk',
  social: {
    twitter: {
      url: '/social/links/twitter',
      name: 'BreakevenGC'
    },
    x: {
      url: '/social/links/x',
      name: 'BreakevenGC'
    },
    instagram: {
      url: '/social/links/instagram',
      name: 'BreakevenGC'
    },
    facebook: {
      url: '/social/links/facebook',
      name: 'BreakevenGC'
    },
    linkedin: {
      url: '/social/links/linkedin',
      name: 'BreakevenUK'
    }
  },
  siteMap: {
    quick: [{
      title: 'Looking for Support?',
      items: [{
        title: 'Problem Gambling',
        link: '/problem-gambling',
        description: 'The aim of problem gambling counselling at Breakeven is to provide an opportunity for you to work toward increasing your well-being by becoming free of problem gambling.'
      }, {
        title: 'Family & Friends',
        link: '/family-and-friends',
        description: 'At Breakeven we understand how difficult it is for the partner, family member or friend of the problem gambler. It can cause considerable strain on a relationship and can impact the whole household and extended family if someone’s gambling is out of control.'
      }, {
        title: 'Types of Support',
        link: '/support',
        description: 'You will work with the counsellor to discover the best way to address your problem gambling and destructive behaviours.'
      }]
    }, {
      title: 'Steps to Take',
      items: [{
        title: 'What is Problem Gambling?',
        link: '/what-is-problem-gambling',
        description: 'Problem gambling is when an individual has an urge to gamble regardless of the harmful, negative consequences which can occur. It can have serious, significant impacts on finances, relationships and on the overall wellbeing of not just themselves but also their loved ones. Individuals living with a problematic gambling may have a desire to stop their behaviour, but they are unable to do so.'
      }, {
        title: 'Self Assessment',
        link: '/self-assessment',
        description: 'Alongside reaching out to one of our team for specialist support, there are also lots of practical tools and resources that you may want to consider when looking at reducing gambling related harm.'
      }, {
        title: 'Client Journey',
        link: '/client-journey',
        description: "At Breakeven, we believe it’s important for our clients to know what expect at each step of their treatment. Our map below shows represents a client’s journey with us, starting at telephone assessment and going all the way through to aftercare."
      }]
    }, {
      title: 'Professionals',
      items: [{
        title: 'Referrals & Screening Questions',
        link: '/professionals/referrals',
        description: 'This form is for professionals to make a direct referral into Breakeven on behalf of someone who is experiencing gambling related harm.'
      }, {
        title: 'Training and Accreditation',
        link: '/professionals/training-and-accreditation',
        description: 'At Breakeven, we offer specialised Gambling Awareness Training which is designed to upskill or train staff about how best to either help or identify people suffering gambling related harm.'
      }, {
        title: 'Support Leaflets',
        link: '/professionals/support-leaflets',
        description: 'Gamcare provide a large range of Leaflets for you to download or order. If you require Breakeven leaflets please email info@breakeven.org.uk with your requirements.'
      }]
    }],
    main: [{
      title: 'Support',
      items: [{
        title: 'Problem Gambling',
        link: '/problem-gambling',
        info: ''
      }, {
        title: 'Types of Support',
        link: '/support'
      }, {
        title: 'Groups',
        link: '/aftercare#green-shoot'
      }, {
        title: 'Family & Friends',
        link: '/family-and-friends'
      }, {
        title: 'Support for women',
        link: '/support-for-women'
      }]
    }, {
      title: 'Overview',
      items: [{
        title: 'Waiting List',
        link: '/waiting-list'
      }, {
        title: 'Social Media',
        link: '/socials'
      }, {
        title: 'Podcast',
        link: '/podcast'
      }, {
        title: 'Other Organisations',
        link: '/organisations'
      }, {
        title: 'About Us',
        link: '/about'
      }, {
        title: 'Our Offices',
        link: '/offices'
      }, {
        title: 'Work for Us',
        link: '/work-for-us'
      }]
    }, {
      title: 'Steps to Take',
      items: [{
        title: 'What is Problem Gambling?',
        link: '/what-is-problem-gambling'
      }, {
        title: 'Client Journey',
        link: '/client-journey'
      }, {
        title: 'Self Assessment',
        link: '/self-assessment'
      }, {
        title: 'Relapse Prevention',
        link: '/relapse-prevention'
      }, {
        title: 'Aftercare',
        link: '/aftercare'
      }, {
        title: 'Recovery Toolkit',
        link: '/self-assessment#recovery-toolkit'
      }, {
        title: 'Money Management',
        link: '/self-assessment#money-management'
      }]
    }, {
      title: 'Professionals',
      items: [{
        title: 'Referrals & Screening Questions',
        link: '/professionals/referrals'
      }, {
        title: 'Training & Engagement',
        link: '/professionals/training-and-accreditation'
      }, {
        title: 'Support Leaflets',
        link: '/professionals/support-leaflets'
      }, {
        title: 'Criminal Justice',
        link: '/professionals/criminal-justice'
      //}, {
        //title: 'GAP: Gambling Awareness and Prevention Programme',
        //link: '/professionals/gap'
      }]
    }]
  },
  offices: []
}

export default Config
