import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import styles from './ScrollToTop.module.scss'

export default function ScrollToTop({
  isHidden,
  onScrollToTop
}) {
  return (
    <div className={[styles.scrollToTop, isHidden ? styles.isHidden : ''].join(' ')} onClick={onScrollToTop}>
      <div className={styles.scrollToTopWrapper}>
        <FontAwesomeIcon icon={faArrowUp} />
      </div>
    </div>
  )
}