import React, { useEffect, useState } from 'react'

const useViewport = () => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)

      window.addEventListener("resize", handleWindowResize)
    }

    return () => {
      if (window) {
        window.removeEventListener("resize", handleWindowResize)
      }
    }
  }, [])
      
  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  return { 
    width,
    height
  }
}

export default useViewport